import { Controller } from "@hotwired/stimulus"
import { hide } from "../utils"

export default class extends Controller {
  handleSubmission(event) {
    if (event.detail.success) {
      hide(this.element.querySelector("form"))
      hide(this.element.querySelector(".success-message"))
    }
  }
}
