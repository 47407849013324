import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="manual-price-check-form"
export default class extends Controller {
  static targets = [
    "removeLineItemButton",
    "fullForm",
    "briefForm",
    "notAvailableForm",
    "quantityInput",
    "unitPricingInput",
    "annualPriceInput",
    "lowEndPricePerUnitInput",
    "highEndPricePerUnitInput",
    "medianPricePerUnitInput",
    "lowEndTotalAnnualPriceInput",
    "highEndTotalAnnualPriceInput",
    "medianTotalAnnualPriceInput",
    "currencyField",
  ]
  removeLineItemButtonTargets: HTMLLinkElement[]
  fullFormTarget: HTMLFormElement
  briefFormTarget: HTMLFormElement
  notAvailableFormTarget: HTMLFormElement
  quantityInputTarget: HTMLInputElement
  unitPricingInputTarget: HTMLInputElement
  annualPriceInputTarget: HTMLInputElement
  lowEndPricePerUnitInputTarget: HTMLInputElement
  highEndPricePerUnitInputTarget: HTMLInputElement
  medianPricePerUnitInputTarget: HTMLInputElement
  lowEndTotalAnnualPriceInputTarget: HTMLInputElement
  highEndTotalAnnualPriceInputTarget: HTMLInputElement
  medianTotalAnnualPriceInputTarget: HTMLInputElement
  currencyFieldTargets: HTMLSelectElement[]

  connect() {
    this.refreshState()
    addEventListener("nested-form:change", this.refreshState.bind(this))
  }

  disconnect() {
    removeEventListener("nested-form:change", this.refreshState.bind(this))
  }

  refreshState() {
    this.updateSKULabels()
    this.updateDeleteDisabledState()
  }

  currencyFieldTargetConnected(element: HTMLSelectElement) {
    element.tomselect?.setValue(this.currencyFieldTargets[0].value)
  }

  onCurrencyChange(event: Event) {
    const currency = event.target.value

    if (this.hasCurrencyFieldTarget) {
      this.currencyFieldTargets.forEach((currencyField) => {
        if (currencyField.tomselect?.getValue() !== currency) {
          currencyField.tomselect?.setValue(currency)
        }
      })
    }
  }

  calculateToUnitPricing(e) {
    e.preventDefault()
    let annualPrice = parseFloat(this.annualPriceInputTarget.value.replace(/,/g, ""))
    let quantity = parseFloat(this.quantityInputTarget.value.replace(/,/g, ""))
    let output = annualPrice / quantity
    this.unitPricingInputTarget.value = output.toString()
    this.unitPricingInputTarget.dispatchEvent(new Event("blur"))
  }

  calculateToAnnualPrice(e) {
    e.preventDefault()
    let quantity = parseFloat(this.quantityInputTarget.value.replace(/,/g, ""))
    let unitPrice = parseFloat(this.unitPricingInputTarget.value.replace(/,/g, ""))
    let output = quantity * unitPrice
    this.annualPriceInputTarget.value = output.toString()
    this.annualPriceInputTarget.dispatchEvent(new Event("blur"))
  }

  // NOTE Any changes to calculateToAnnualTotal* functions
  // should be mirrored in Ruby in the Contracts::ManualPriceCheckAttributes service concern
  calculateToAnnualTotalLow() {
    let quantity = parseFloat(this.quantityInputTarget.value.replace(/,/g, ""))
    let lowEndPricePerUnit = parseFloat(this.lowEndPricePerUnitInputTarget.value.replace(/,/g, ""))
    let output = quantity * lowEndPricePerUnit
    this.lowEndTotalAnnualPriceInputTarget.value = output.toString()
    this.lowEndTotalAnnualPriceInputTarget.dispatchEvent(new Event("blur"))
  }

  calculateToAnnualTotalHigh() {
    let quantity = parseFloat(this.quantityInputTarget.value.replace(/,/g, ""))
    let highEndPricePerUnit = parseFloat(this.highEndPricePerUnitInputTarget.value.replace(/,/g, ""))
    let output = quantity * highEndPricePerUnit
    this.highEndTotalAnnualPriceInputTarget.value = output.toString()
    this.highEndTotalAnnualPriceInputTarget.dispatchEvent(new Event("blur"))
  }

  calculateToAnnualTotalMedian() {
    let quantity = parseFloat(this.quantityInputTarget.value.replace(/,/g, ""))
    let medianPricePerUnit = parseFloat(this.medianPricePerUnitInputTarget.value.replace(/,/g, ""))
    let output = quantity * medianPricePerUnit
    this.medianTotalAnnualPriceInputTarget.value = output.toString()
    this.medianTotalAnnualPriceInputTarget.dispatchEvent(new Event("blur"))
  }

  updateSKULabels() {
    this.visibleSKUTargets().forEach((skuElement, index) => {
      skuElement.querySelector("h2").textContent = `SKU ${index + 1}`
    })
  }

  visibleSKUTargets() {
    return this.element.querySelectorAll(
      'section.mpc_line_item:not([style*="display:none"]):not([style*="display: none"])',
    )
  }

  // We don't allow removing the last line item on a form
  updateDeleteDisabledState() {
    let skuCount = this.visibleSKUTargets().length
    if (skuCount === 1) {
      this.removeLineItemButtonTargets.forEach((button) => {
        button.disabled = true
      })
    } else {
      this.removeLineItemButtonTargets.forEach((button) => {
        button.disabled = false
      })
    }
  }

  updateFormType(e) {
    this.mirrorFormTypeSelection(e.currentTarget.value)

    if (e.currentTarget.value === "full") {
      this.switchFormToFull()
    } else if (e.currentTarget.value === "brief") {
      this.switchFormToBrief()
    } else {
      this.switchFormToNotAvailable()
    }
  }

  mirrorFormTypeSelection(selection: String) {
    this.element
      .querySelectorAll(`input[name="manual_price_check[price_check_type]"][value="${selection}"]`)
      .forEach((input: HTMLInputElement) => {
        input.checked = true
      })
  }

  switchFormToFull() {
    this.briefFormTarget.classList.add("hidden")
    this.notAvailableFormTarget.classList.add("hidden")
    this.fullFormTarget.classList.remove("hidden")

    this.fullFormTarget.id = "manual_price_check_form"
    this.briefFormTarget.id = "brief_manual_price_check_form"
    this.notAvailableFormTarget.id = "not_available_manual_price_check_form"
  }

  switchFormToBrief() {
    this.fullFormTarget.classList.add("hidden")
    this.notAvailableFormTarget.classList.add("hidden")
    this.briefFormTarget.classList.remove("hidden")

    this.fullFormTarget.id = "full_manual_price_check_form"
    this.briefFormTarget.id = "manual_price_check_form"
    this.notAvailableFormTarget.id = "not_available_manual_price_check_form"
  }

  switchFormToNotAvailable() {
    this.fullFormTarget.classList.add("hidden")
    this.briefFormTarget.classList.add("hidden")
    this.notAvailableFormTarget.classList.remove("hidden")

    this.fullFormTarget.id = "full_manual_price_check_form"
    this.briefFormTarget.id = "brief_manual_price_check_form"
    this.notAvailableFormTarget.id = "manual_price_check_form"
  }
}
