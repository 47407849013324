import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loaderContainer", "delayedResponseMessage"]
  static values = {
    interval: { type: Number },
  }

  hasLoaderContainerTarget: boolean
  loaderContainerTarget: HTMLDivElement
  delayedResponseMessageTarget: HTMLDivElement
  intervalValue: number
  abortController: AbortController | undefined

  connect(): void {
    if (this.hasLoaderContainerTarget) {
      this.abortController = new AbortController()
      this.showMessages()
    }
  }

  disconnect(): void {
    this.abortController?.abort()
  }

  showMessages(): void {
    const messages = Array.from(this.delayedResponseMessageTarget.children) as HTMLElement[]

    messages.forEach((message, index) => {
      if (index === 0) return // First message is already visible

      const delay = index * this.intervalValue

      setTimeout(() => {
        if (this.abortController?.signal.aborted) return

        if (index > 0) {
          // Clean up previous message animations
          messages[index - 1].classList.remove("animate-slide-up-in")
          messages[index - 1].classList.add("animate-slide-up-out", "opacity-0")
        }

        // Show current message
        message.classList.remove("opacity-0", "translate-y-[20px]")
        message.classList.add("animate-slide-up-in")
      }, delay)
    })
  }
}
