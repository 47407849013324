import { Controller } from "@hotwired/stimulus"
import { fullyDisableElement } from "../../../utils/request_form/disable_utils"

// Connects to data-controller="ai--request-form--supplier-search"
export default class extends Controller {
  connect() {
    this.element.addEventListener("select", this.submitSupplier.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("select", this.submitSupplier.bind(this))
  }

  submitSupplier(e) {
    const supplierId = e.detail.value
    const supplierName = this.element.querySelector('[data-test="selected-supplier-display-name"]').textContent.trim()
    const value = `<span data-supplier-id="${supplierId}">${supplierName}</span>`

    const formElement = this.element.closest("form")
    const fieldController = this.application.getControllerForElementAndIdentifier(
      formElement,
      "ai--request-form--field",
    )

    this.handleFormSubmitted()
    fieldController.submitValue(
      new CustomEvent("supplierSearch", {
        detail: { value: value },
      }),
    )
  }

  handleFormSubmitted = () => {
    this.element
      .querySelector(".supplier-search")
      .classList.add("bg-gray-50", "cursor-not-allowed", "border-base", "hover:border-base")
    this.element.querySelector("#supplier-search_cancel_selection").classList.add("hidden")
  }
}
