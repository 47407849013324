import { Controller } from "@hotwired/stimulus"

/*
Please be mindful before adding new methods to this controller. Ideally, each
agent should have its own controller to avoid conflicts and to keep the code clean.

Please avoid adding utility functions to this controller unless they are clearly generic
and can be reused across multiple places. Keep the controller focused on its core responsibilities
to maintain clarity and manageability.

Remember you can always have a form that posts to the chat endpoint. You don't need
to use the textarea or the form from the sidecar.
*/

// Connects to data-controller="ai--global-assistant"
export default class extends Controller {
  DEFAULT_MESSAGE_INPUT_HEIGHT = 40
  MAX_MESSAGE_INPUT_HEIGHT = 150

  static targets = ["form", "messageTextarea"]

  formTarget: HTMLFormElement
  messageTextareaTarget: HTMLTextAreaElement
  selectedOptions: string[]

  connect() {
    this.messageTextareaTarget.addEventListener("keypress", this.handleMessageKeypress)
    this.messageTextareaTarget.addEventListener("input", this.handleMessageInput)

    this.formTarget.addEventListener("submit", this.validateForm)
    this.formTarget.addEventListener("turbo:submit-start", this.onSubmitForm)
    this.formTarget.addEventListener("turbo:submit-end", this.onSubmitFormEnd)

    if (this.messageTextareaTarget.value.trim().length > 0) {
      this.resizeMessageInput()
      this.messageTextareaTarget.selectionStart = this.messageTextareaTarget.value.length
    }
  }

  disconnect() {
    this.formTarget.removeEventListener("submit", this.validateForm)
    this.formTarget.removeEventListener("turbo:submit-start", this.onSubmitForm)
    this.formTarget.removeEventListener("turbo:submit-end", this.onSubmitFormEnd)

    this.messageTextareaTarget.removeEventListener("keypress", this.handleMessageKeypress)
    this.messageTextareaTarget.removeEventListener("input", this.handleMessageInput)
  }

  resizeMessageInput = () => {
    if (this.messageTextareaTarget.scrollHeight < this.MAX_MESSAGE_INPUT_HEIGHT) {
      this.messageTextareaTarget.style.height = `${this.messageTextareaTarget.scrollHeight}px`
    }
  }

  handleMessageInput = () => {
    this.dispatch("message-input", { detail: { content: this.messageTextareaTarget.value } })
  }

  handleMessageKeypress = (event: KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      this.formTarget.requestSubmit()
    }
  }

  validateForm = (e) => {
    if (this.messageTextareaTarget.value.trim() === "") e.preventDefault()
  }

  onSubmitForm = () => {
    this.dispatch("submit", { detail: { content: this.messageTextareaTarget.value } })

    this.messageTextareaTarget.value = ""
    this.messageTextareaTarget.style.height = `${this.DEFAULT_MESSAGE_INPUT_HEIGHT}px`
  }

  onSubmitFormEnd = () => {
    this.messageTextareaTarget.focus()
  }

  // The agent may return multiple options for the user to choose from.
  // Each option is a button that submits the form with its value as the message.
  submitButtonOption = (event: Event) => {
    event.preventDefault()
    const value = (event.currentTarget as HTMLButtonElement).value

    this.messageTextareaTarget.value = value
    this.formTarget.requestSubmit()
  }
}
