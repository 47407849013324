import { Controller } from "@hotwired/stimulus"
import { buildUrl } from "../utils/urls"
import { debounce } from "lodash"
import { show } from "../utils"

const SUPPLIER_SEARCH_BASE_URL = "/supplier_search"
const SUPPLIER_SEARCH_DOMAIN_BASE_URL = `${SUPPLIER_SEARCH_BASE_URL}/search_domain`

interface FrameElement extends HTMLElement {
  src: string
}

// Connects to data-controller="supplier-search"
export default class extends Controller {
  /* Targets */
  static targets = [
    "resultsFrame",
    "domainFrame",
    "input",
    "inputError",
    "domainInput",
    "option",
    "selection",
    "coupaSelection",
    "icon",
    "domainParams",
    "searchGlassIcon",
    "clearInputIcon",
  ]
  resultsFrameTarget: FrameElement
  domainFrameTarget: FrameElement
  inputTarget: HTMLInputElement
  inputErrorTarget: HTMLDivElement
  domainInputTarget: HTMLInputElement
  domainParamsTarget: HTMLDivElement
  selectionTarget: HTMLInputElement
  coupaSelectionTarget: HTMLInputElement
  iconTarget: HTMLDivElement
  iconTargets: [HTMLDivElement]
  optionTargets: [HTMLLIElement]
  hasResultsFrameTarget: boolean
  hasDomainFrameTarget: boolean
  hasSelectionTarget: boolean
  hasCoupaSelectionTarget: boolean
  hasInputTarget: boolean
  hasInputErrorTarget: boolean
  searchGlassIconTarget: SVGAElement
  clearInputIconTarget: SVGAElement

  /* Values */
  static values = {
    supplier: String,
    output: String,
    label: String,
    withoutLabel: Boolean,
    withoutNew: Boolean,
    placeholder: String,
    required: Boolean,
    orgId: String,
    clearOnBlur: Boolean,
    frameId: String,
    coupaEnabled: Boolean,
    coupaOutput: String,
    changesDisabled: Boolean,
    disabled: Boolean,
  }
  supplierValue: string
  outputValue: string
  labelValue: string
  withoutLabelValue: boolean
  withoutNewValue: boolean
  placeholderValue: string
  requiredValue: boolean
  orgIdValue: string
  clearOnBlurValue: boolean
  frameIdValue: string
  coupaEnabledValue: boolean
  coupaOutputValue: string
  changesDisabledValue: boolean
  disabledValue: boolean

  /* Lifecycle */
  connect() {
    document.addEventListener("click", this.onClickOutsideOfResults)
    document.addEventListener("enableSupplierChanges", this.enableChanges)
    const form = this.element.closest("form")
    form?.addEventListener("keypress", this.onFormKeyDown)
  }

  disconnect() {
    document.removeEventListener("click", this.onClickOutsideOfResults)
    const form = this.element.closest("form")
    form?.removeEventListener("keypress", this.onFormKeyDown)
    if (this.hasInputTarget) {
      this.inputTarget.value = ""
    }
    if (this.hasResultsFrameTarget) {
      this.resultsFrameTarget.src = null
      this.resultsFrameTarget.innerHTML = ""
    }
  }

  initialize() {
    if (this.hasSelectionTarget) {
      this.updateOutput(this.selectionTarget.value)
    }
    if (this.hasCoupaSelectionTarget) {
      this.updateCoupaOutput(this.coupaSelectionTarget.value)
    }

    this.onSearch = debounce(this.onSearch.bind(this), 200)
  }

  selectionTargetConnected() {
    this.updateOutput(this.selectionTarget.value)
    this.updateCoupaOutput(this.coupaSelectionTarget.value)
    this.onOptionSelect(this.selectionTarget.value)
  }

  selectionTargetDisconnected() {
    this.updateOutput("")
    this.updateCoupaOutput("")
    this.onOptionSelect("")
  }

  inputErrorTargetConnected() {
    this.hideError()
  }

  domainParamsTargetConnected(t) {
    const { domain, result, errors, domainisvalid: domainIsValid } = t.dataset
    this.iconUpdate(domain, result, errors, domainIsValid)
  }

  domainInputTargetConnected(t) {
    this.iconTargets.forEach((icon) => (icon.hidden = true))
    t.focus()
    t.selectionStart = t.selectionEnd = t.value.length
  }

  iconUpdate(domain, result, errors, domainIsValid) {
    if (result === "true") {
      this.iconTargets.forEach((icon) => (icon.id === "check" ? (icon.hidden = false) : (icon.hidden = true)))
    } else if (errors === "true" && domainIsValid === "true") {
      this.iconTargets.forEach((icon) => (icon.id === "triangle" ? (icon.hidden = false) : (icon.hidden = true)))
    } else if (domain.length > 0) {
      this.iconTargets.forEach((icon) => (icon.id === "spin" ? (icon.hidden = false) : (icon.hidden = true)))
    } else if (domain.length === 0) {
      this.iconTargets.forEach((icon) => (icon.hidden = true))
    }
  }

  /* Actions */

  // Prevent enter from submitting the top-level form when a supplier search field
  onFormKeyDown(e) {
    if (e.target.dataset.supplierSearchTarget && e.code == "Enter") {
      e.preventDefault()
      return false
    }
  }

  onSearch(e) {
    if (e.code == "ArrowDown") {
      this.focusOption(0)
    } else if (e.code == "Enter") {
      this.focusOption(0, true)
    } else {
      const params = {
        q: this.inputTarget.value,
        label: this.labelValue,
        without_label: this.withoutLabelValue,
        without_new: this.withoutNewValue,
        placeholder: this.placeholderValue,
        required: this.requiredValue,
        org_id: this.orgIdValue,
        frame_id: this.frameIdValue,
        coupa_enabled: this.coupaEnabledValue,
        changes_disabled: this.changesDisabledValue,
        disabled: this.disabledValue,
      }

      this.resultsFrameTarget.src = buildUrl(SUPPLIER_SEARCH_BASE_URL, params)
    }
  }

  onDomainSearch(e) {
    if (e.code == "ArrowDown") {
      this.focusOption(0)
    } else {
      const params = {
        domain: this.domainInputTarget.value,
        frame_id: this.frameIdValue,
      }

      this.domainFrameTarget.src = buildUrl(SUPPLIER_SEARCH_DOMAIN_BASE_URL, params)
    }
  }

  onClickOutsideOfResults = (e) => {
    if (!this.hasResultsFrameTarget) {
      return false
    }
    const isClickInsideElement = this.element.contains(e.target)
    if (!isClickInsideElement) {
      this.resultsFrameTarget.src = null
      this.resultsFrameTarget.innerHTML = ""

      if (!this.hasInputTarget) {
        return false
      }

      if (this.clearOnBlurValue) {
        this.inputTarget.value = ""
      }
    }
  }

  focusOption(i, click = false) {
    const option = this.optionTargets[i]
    if (option) {
      option.focus()
      if (click) option.getElementsByTagName("a")[0].click()
    }
  }

  onOptionKeyUp(e) {
    if (e.code == "ArrowDown") {
      this.focusOption(e.params.id + 1)
    } else if (e.code == "ArrowUp") {
      this.focusOption(e.params.id - 1)
    } else if (e.code == "Enter") {
      e.target.getElementsByTagName("a")[0].click()
    }
  }

  updateOutput(val) {
    const outputValueElem = document.getElementById(this.outputValue)

    if (outputValueElem) {
      outputValueElem.setAttribute("value", val)
      const inputEvent = new Event("input")
      outputValueElem.dispatchEvent(inputEvent)
    } else {
      this.hideError()
    }
  }

  updateCoupaOutput(val) {
    const coupaOutputValueElem = document.getElementById(this.coupaOutputValue)

    if (coupaOutputValueElem) {
      coupaOutputValueElem.setAttribute("value", val)
      const inputEvent = new Event("input")
      coupaOutputValueElem.dispatchEvent(inputEvent)
    } else {
      this.hideError()
    }
  }

  onFocus(e) {
    const inputValue = e.target.value

    if (!this.clearOnBlurValue && inputValue) {
      setTimeout(() => {
        e.target.dispatchEvent(new KeyboardEvent("keyup"))
      }, 100)
    }
  }

  onBlur(e) {
    if (this.clearOnBlurValue) {
      return
    }
    if (this.inputTarget.value) {
      this.showError()
    } else {
      this.hideError()
    }
  }

  showError() {
    this.hasInputTarget && this.inputTarget.classList.add("border-red-500")
    this.hasInputErrorTarget && this.inputErrorTarget.classList.remove("hidden")
  }

  hideError() {
    this.hasInputTarget && this.inputTarget.classList.remove("border-red-500")
    this.hasInputErrorTarget && this.inputErrorTarget.classList.add("hidden")
  }

  onOptionSelect(value: string) {
    this.element.dispatchEvent(new CustomEvent("select", { bubbles: true, detail: { value } }))
  }

  enableChanges() {
    if (this.hasSelectionTarget) {
      this.selectionTarget.disabled = false
    }
    const supplierSearch = document.querySelector(".supplier-search")
    supplierSearch.classList.remove("bg-gray-50", "cursor-not-allowed", "border-base", "hover:border-base")
    const supplierSearchCancelLink = document.getElementById("supplier-search_cancel_selection")
    show(supplierSearchCancelLink)
  }
}
