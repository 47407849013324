import { Controller } from "@hotwired/stimulus"
import { fullyDisableElement } from "../../../utils/request_form/disable_utils"
import { disableStyledSelect } from "../../../utils"

// Connects to data-controller="ai--request-form--field"
export default class extends Controller {
  static targets = ["form", "messageTextarea"]

  formTarget: HTMLFormElement
  messageTextareaTarget: HTMLInputElement
  selectedOptions: string[]

  connect() {}

  submitButtonOption = (event: Event) => {
    event.preventDefault()
    const value = (event.currentTarget as HTMLButtonElement).value

    this.submitFormValue(value)
  }

  submitValue = (event: Event) => {
    event.preventDefault()
    const value = event instanceof CustomEvent ? event.detail.value : event.params.value

    this.submitFormValue(value)
  }

  toggleCheckbox(event) {
    event.preventDefault()
    const checkbox = event.currentTarget
    const value = checkbox.value

    if (!Array.isArray(this.selectedOptions)) this.selectedOptions = []

    if (checkbox.checked) this.selectedOptions.push(value)
    else this.selectedOptions = this.selectedOptions.filter((option) => option !== value)
  }

  submitCheckboxOptions = (event: Event) => {
    event.preventDefault()
    this.submitFormValue(this.selectedOptions.join(", "))
  }

  // PB: Method used for contract selection in the AI Assistant
  submitContracts = (event: Event) => {
    event.preventDefault()
    if (!Array.isArray(this.selectedOptions)) this.selectedOptions = []
    if (this.selectedOptions.length === 0) {
      this.submitFormValue("No Contract")
    } else {
      this.selectedOptions = this.selectedOptions.map((option) => {
        const optionElement = document.getElementById(`chat_contract_option_${option}`) as HTMLElement
        if (optionElement) {
          return optionElement.outerHTML
        }
      })
      this.submitFormValue(this.selectedOptions.join("</br>"))
    }
  }

  selectSuggestion = (event: Event) => {
    event.preventDefault()

    const target = event.currentTarget as HTMLElement
    const suggestionValue = target.dataset.suggestionValue

    this.submitFormValue(suggestionValue)
  }

  submitSelectOption = (event: Event) => {
    const selectField = event.target.tomselect
    const value = selectField.getValue()
    const chosenOption = selectField.getItem(value)
    this.submitFormValue(chosenOption.outerHTML)
  }

  submitFormValue = (value) => {
    this.lockResponseInputs(false)
    this.messageTextareaTarget.value = value
    this.formTarget.requestSubmit()
    this.disconnect()
  }

  lockResponseInputs = (disconnect = true) => {
    this.element.querySelectorAll("label:not(#category-label)").forEach((el: HTMLLabelElement) => {
      fullyDisableElement(el)
    })
    this.element.querySelectorAll("input").forEach((el: HTMLInputElement) => {
      if (el !== this.messageTextareaTarget) {
        fullyDisableElement(el)
      }
    })
    this.element.querySelectorAll("button").forEach((el: HTMLButtonElement) => {
      fullyDisableElement(el)
    })
    this.element.querySelectorAll("select:not(.is-tom-select)").forEach((el: HTMLSelectElement) => {
      fullyDisableElement(el)
    })
    this.element.querySelectorAll("select.is-tom-select").forEach((el: HTMLSelectElement) => {
      disableStyledSelect(el)
    })
    if (disconnect) {
      this.disconnect()
    }
  }
}
