import { Controller } from "@hotwired/stimulus"
import FormEditor__QuestionSortController from "./question_sort_controller"

// Connects to data-controller="form-editor--question-properties"
export default class extends Controller {
  static outlets = ["form-editor--question-sort"]
  formEditorQuestionSortOutlet: FormEditor__QuestionSortController

  static targets = ["kind", "properties", "prompt", "multiple", "currency", "options", "checkboxOptions"]

  kindTarget: HTMLSelectElement
  propertiesTarget: HTMLDivElement
  promptTarget: HTMLTemplateElement
  multipleTarget: HTMLTemplateElement
  currencyTarget: HTMLTemplateElement
  optionsTarget: HTMLTemplateElement
  checkboxOptionsTarget: HTMLTemplateElement

  readonly formFields = {
    TextField: ["required", "placeholder"],
    TextArea: ["required", "placeholder"],
    RadioButton: ["required", "options"],
    Checkbox: ["checkboxOptions"],
    Select: ["prompt", "required", "options"],
    DateField: ["required"],
    AmountRangeInput: ["required", "currency"],
    UserSelect: ["required", "prompt"],
    SupplierSelect: ["required", "multiple"],
    DepartmentSelect: ["required"],
    FileField: ["required", "multiple"],
    FormResult: [],
  }

  connect(): void {
    this.setupHiddenFieldWithDisplayOrder()
    this.updateForm()
  }

  setupHiddenFieldWithDisplayOrder() {
    const displayOrder = this.formEditorQuestionSortOutlet.getOrderedFormFieldKeys()
    const input = document.createElement("input")
    input.setAttribute("name", `question[display_order]`)
    input.setAttribute("type", "hidden")
    input.setAttribute("value", displayOrder.join(","))
    this.element.appendChild(input)
  }

  updateForm() {
    const kind = this.kindTarget.value
    const displayFields = this.formFields[kind] || []
    this.showFormFields(displayFields)
    this.toggleCaption(kind)
  }

  showFormFields(formFields) {
    this.removeDisplayedFormFields()
    Object.entries({
      prompt: this.promptTarget,
      multiple: this.multipleTarget,
      currency: this.currencyTarget,
      options: this.optionsTarget,
      checkboxOptions: this.checkboxOptionsTarget,
    }).forEach(([fieldName, fieldContainer]) => {
      if (formFields.includes(fieldName)) {
        const newFormField = fieldContainer.content.cloneNode(true)
        this.propertiesTarget.appendChild(newFormField)
      }
    })
  }

  toggleCaption(kind: string): void {
    const captionElement = document.querySelector(".kind-caption") as HTMLElement
    const captionTextElement = document.querySelector(".kind-caption-text") as HTMLElement

    if (!captionElement || !captionTextElement) {
      return
    }

    if (kind === "SupplierSelect" || kind === "DepartmentSelect") {
      captionElement.classList.remove("hidden")
      captionTextElement.textContent = kind === "SupplierSelect" ? "supplier" : "department"
    } else {
      captionElement.classList.add("hidden")
      captionTextElement.textContent = ""
    }
  }

  removeDisplayedFormFields() {
    const propertiesChildren = this.propertiesTarget.children
    const propertiesElementsArray = Array.from(propertiesChildren)
    propertiesElementsArray.forEach((element) => {
      if (!(element instanceof HTMLTemplateElement) && element.id !== "required_container") {
        this.propertiesTarget.removeChild(element)
      }
    })
  }
}
