import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../../utils/index"

// Connects to data-controller="ai--global-assistant-welcome"
export default class extends Controller {
  static targets = ["intro", "hello", "goodbye"]
  static values = {
    activePanel: String,
  }

  introTarget: HTMLElement
  helloTarget: HTMLElement
  goodbyeTarget: HTMLElement
  activePanelValue: string

  connect() {
    if (this.activePanelValue) {
      const targetMap = {
        intro: this.introTarget,
        hello: this.helloTarget,
        goodbye: this.goodbyeTarget,
      }

      const showElement = targetMap[this.activePanelValue]
      const hideElement = this.introTarget

      if (showElement && showElement !== hideElement) {
        this.showComponent(showElement, hideElement)
      }
    }
  }

  showComponent(showElement: HTMLElement, hideElement: HTMLElement) {
    hide(hideElement)
    show(showElement)
  }

  startTour() {
    this.showComponent(this.helloTarget, this.introTarget)
  }

  skipTour() {
    this.showComponent(this.goodbyeTarget, this.introTarget)
  }
}
