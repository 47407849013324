import { Controller } from "@hotwired/stimulus"
import { NestedFormSyncer } from "../../utils/comment_form/NestedFormSyncer"

// Connects to data-controller="workflows--billing-details-modal"
export default class extends Controller {
  static targets = [
    "billingDetailsSelect",
    "billingDetailsError",
    "commentContentHiddenField",
    "commentInternalHiddenField",
    "billingDetailsForm",
  ]
  billingDetailsSelectTarget: HTMLSelectElement
  billingDetailsErrorTarget: HTMLDivElement
  commentContentHiddenFieldTarget: HTMLInputElement
  commentInternalHiddenFieldTarget: HTMLInputElement
  billingDetailsFormTarget: HTMLFormElement

  static values = {
    output: String,
    commentFormId: {
      type: String,
      default: "task-comment-form",
    },
  }
  outputValue: string
  commentFormIdValue: string

  connect() {
    if (this.outputValue) {
      this.outputSelectedOption()
    }
  }

  outputSelectedOption() {
    const targetElement = document.getElementById(this.outputValue)
    const selectedOption = this.billingDetailsSelectTarget[this.billingDetailsSelectTarget.selectedIndex]

    targetElement.value = selectedOption.value ? selectedOption.text : ""
    targetElement.setAttribute("data-clipboard-input-value", selectedOption.value ? selectedOption.text : "")
  }

  submitForm() {
    if (this.billingDetailsFormTarget && this.isFormValid()) {
      // before submitting, we need to get the comment form values
      NestedFormSyncer.call(this.billingDetailsFormTarget)

      const commentForm = document.getElementById(this.commentFormIdValue) || document
      const commentContentField = commentForm.querySelector("#comment-content-field")
      if (commentContentField) {
        this.commentContentHiddenFieldTarget.value = commentContentField.value
      }

      const commentInternalField = commentForm.querySelector("#comment-internal-field")
      if (commentInternalField) {
        this.commentInternalHiddenFieldTarget.value = commentInternalField.value
      }

      this.dispatch("form-submitted")
      /*
      requestSubmit() sets format to :turbo_stream
      submit() sets the format to html
      Below ternary makes sure that request comes in as a turbo_stream when tasks are being completed from workflows
      */
      if (window.location.href.includes("/request_steps")) {
        this.billingDetailsFormTarget.requestSubmit()
      } else {
        window.location.href.includes("/portal") || window.location.href.includes("/supplier_portal")
          ? this.billingDetailsFormTarget.submit()
          : this.billingDetailsFormTarget.requestSubmit()
      }
    }
  }

  isFormValid() {
    let isValid = true

    const errorElement = document.getElementById("billing-details-error")
    const selectElement = document.getElementById("request_billing_details_billing_detail_id")
    this.hideError(this.billingDetailsErrorTarget, this.billingDetailsSelectTarget)

    if (this.billingDetailsSelectTarget.selectedIndex < 1) {
      this.showError(this.billingDetailsErrorTarget, this.billingDetailsSelectTarget)
      isValid = false
    }

    return isValid
  }

  showError(errorElement, formElement) {
    errorElement.classList.remove("hidden")
    formElement.classList.add("border-red-500")
  }

  hideError(errorElement, formElement) {
    errorElement.classList.add("hidden")
    formElement.classList.remove("border-red-500")
  }
}
