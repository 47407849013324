import { Controller } from "@hotwired/stimulus"
import { enable, disable, hide } from "../../../utils"

// Connects to data-controller="ai--policy-compliance-checks--new-check-form-component"
export default class extends Controller {
  static targets = ["checkbox", "submit", "form"]

  checkboxTargets: HTMLInputElement[]
  submitTarget: HTMLButtonElement
  formTarget: HTMLElement

  toggleCheckbox(_event) {
    this.updateSubmitButtonState()
  }

  updateSubmitButtonState() {
    const anyChecked = this.checkboxTargets.some((checkbox) => checkbox.checked)
    if (anyChecked) {
      enable(this.submitTarget)
    } else {
      disable(this.submitTarget)
    }
  }

  hideForm() {
    hide(this.formTarget)
  }
}
