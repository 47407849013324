import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../../utils"

// Connects to data-controller="ai--global-assistant-sidecar"
export default class extends Controller {
  SIDECAR_STATE_STORAGE = "sidecarState"
  OPEN = "open"
  CLOSED = "closed"

  static targets = ["sidecar", "expandButton", "collapseButton"]
  static values = { status: String }

  sidecarTarget: HTMLDivElement
  expandButtonTarget: HTMLButtonElement
  collapseButtonTarget: HTMLButtonElement

  statusValue: string

  initialize() {
    this.statusValue = this.OPEN
  }

  hideSidecar() {
    this.statusValue = this.CLOSED
  }

  showSidecar() {
    this.statusValue = this.OPEN
  }

  statusValueChanged() {
    if (this.statusValue === this.OPEN) {
      show(this.sidecarTarget)
      hide(this.expandButtonTarget)
      show(this.collapseButtonTarget)
    } else {
      hide(this.sidecarTarget)
      show(this.expandButtonTarget)
      hide(this.collapseButtonTarget)
    }
  }
}
