import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { getMetaValue } from "../../../utils"
import { fullyDisableElement } from "../../../utils/request_form/disable_utils"

// Connects to data-controller="ai--request-form--bulk-doc-uploader"
export default class extends Controller {
  static targets = ["docUploadWrapper", "form", "submitButton"]
  docUploadWrapperTarget: HTMLDivElement
  submitButtonTarget: HTMLButtonElement

  static values = { url: String }
  urlValue: String

  private observer!: MutationObserver

  connect() {
    this.updateSubmitState()

    // Observer DOM changes within the docUploadWrapperTarget to update the submit button state
    this.observer = new MutationObserver(this.updateSubmitState.bind(this))
    this.observer.observe(this.docUploadWrapperTarget, { childList: true, subtree: true })
  }

  disconnect() {
    this.observer.disconnect()
  }

  updateSubmitState() {
    this.submitButtonTarget.disabled = this.uploadedFileInputs().length === 0
  }

  submitFileUpload = (event: Event) => {
    event.preventDefault()

    const signedIds = Array.from(this.uploadedFileInputs()).map((input: HTMLInputElement) => input.value)
    const message = [
      `Uploaded ${signedIds.length} file${signedIds.length === 1 ? "" : "s"}.`,
      `<MESSAGE_DATA>{"signed_document_ids": ${JSON.stringify(signedIds)}}</MESSAGE_DATA>`,
    ].join("\n")

    const formElement = this.element.closest("form")
    const fieldController = this.application.getControllerForElementAndIdentifier(
      formElement,
      "ai--request-form--field",
    )

    fieldController.submitValue(
      new CustomEvent("uploadedFiles", {
        detail: { value: message },
      }),
    )
    this.handleFormSubmitted()
  }

  uploadedFileInputs(): HTMLInputElement[] {
    return Array.from(this.docUploadWrapperTarget.querySelectorAll('input[name="file_upload[file][]"][type="hidden"]'))
  }

  handleFormSubmitted = () => {
    const largeZone = this.element.querySelector("div[data-docupload-target='largeZone']")
    const largeZoneMsg = this.element.querySelector("#large-zone-msg")
    const smallZoneMsg = this.element.querySelector("#small-zone-msg")
    const uploader = this.element.querySelector("div[controller='docupload']")

    if (largeZone) {
      largeZone.classList.add("hover:border-dashed", "hover:border-base", "hover:cursor-not-allowed")
    }
    if (largeZoneMsg) {
      largeZoneMsg.classList.remove("text-purple-500", "cursor-pointer", "pointer-events-auto")
    }
    if (smallZoneMsg) {
      smallZoneMsg.classList.remove("text-purple-500", "cursor-pointer", "pointer-events-auto")
    }
    if (uploader) {
      uploader.removeAttribute("data-controller")
    }

    this.disconnect()
  }
}
