import { Controller } from "@hotwired/stimulus"

type Datapoint = {
  month: string // Only enforce the presence and type of the 'month' field
  [key: string]: any
}

type MonthRange = {
  beginningOfTheMonth: Date
  endOfMonth: Date
}

// Connects to data-controller="contract-vs-actuals--chart"
export default class extends Controller {
  static values = {
    path: String,
  }
  pathValue: string

  hoverClass = "cursor-pointer"

  // Wired up to the 'click' event dispatched by the `chart-component` controller
  handleClick(event: CustomEvent): void {
    event.preventDefault()

    const { datapoints } = event.detail

    // const url = new URL(this.urlValue)
    const searchParams = this.buildQueryParams(datapoints[0])

    window.location.href = `${this.pathValue}?${searchParams}`
  }

  // Wired up to the 'hover' event dispatched by the `chart-component` controller
  handleHover(event: CustomEvent): void {
    event.preventDefault()
    const { elements: barElements } = event.detail

    // Expects that the chart component is a child of the controller's element
    const canvas = this.element.querySelector("canvas")

    if (this.hasActiveHoverElements(barElements)) {
      canvas.classList.add(this.hoverClass)
    } else {
      canvas.classList.remove(this.hoverClass)
    }
  }

  buildQueryParams(datapoint: Datapoint): string {
    const queryParams = new URLSearchParams(this.pathValue)
    const { beginningOfTheMonth, endOfMonth } = this.extractMonth(datapoint)

    const monthFilterParams = {
      custom_start_date: beginningOfTheMonth.toISOString(),
      custom_end_date: endOfMonth.toISOString(),
      range_back: "1m",
      range_ahead: "1m",
      timeframe_scope: "custom",
    }

    Object.entries(monthFilterParams).forEach(([key, value]) => {
      queryParams.set(`filter_by[${key}]`, value)
    })

    return queryParams.toString()
  }

  extractMonth(datapoint: Datapoint): MonthRange {
    const dateString = datapoint.month

    const beginningOfTheMonth = new Date(dateString)
    const endOfMonth = new Date(beginningOfTheMonth.getFullYear(), beginningOfTheMonth.getMonth() + 1, 0)

    return { beginningOfTheMonth, endOfMonth }
  }

  hasActiveHoverElements(barElements: any[]): boolean {
    return barElements.some((bar: any) => bar.element?.active)
  }
}
