import { Controller } from "@hotwired/stimulus"
import { NestedFormSyncer } from "../../utils/comment_form/NestedFormSyncer"

// Connects to data-controller="workflows--create-contract-modal"
export default class extends Controller {
  static targets = ["commentContentHiddenField", "commentInternalHiddenField", "form"]

  commentContentHiddenFieldTarget: HTMLInputElement
  commentInternalHiddenFieldTarget: HTMLInputElement
  formTarget: HTMLFormElement

  static values = {
    output: String,
    commentFormId: {
      type: String,
      default: "task-comment-form",
    },
  }
  outputValue: string
  commentFormIdValue: string

  submitForm(e) {
    const button = e.target

    if (this.formTarget) {
      // before submitting, we need to get the comment form values
      NestedFormSyncer.call(this.formTarget)

      const commentForm = document.getElementById(this.commentFormIdValue) || document
      const commentContentField = commentForm.querySelector("#comment-content-field")
      if (commentContentField) {
        this.commentContentHiddenFieldTarget.value = (commentContentField as HTMLInputElement).value
      }

      const commentInternalField = commentForm.querySelector("#comment-internal-field")
      if (commentInternalField) {
        this.commentInternalHiddenFieldTarget.value = (commentInternalField as HTMLInputElement).value
      }

      this.dispatch("form-submitted")
      /*
      requestSubmit() sets format to :turbo_stream
      submit() sets the format to html
      Below ternary makes sure that request comes in as a turbo_stream when tasks are being completed from workflows
      */
      if (window.location.href.includes("/portal") || window.location.href.includes("/supplier_portal")) {
        this.formTarget.submit()
      } else {
        this.formTarget.requestSubmit()
      }
      this.disableButton(button)
    }
  }

  disableButton(button: HTMLButtonElement) {
    if (!this.formTarget.checkValidity()) {
      // Do not disable button if there are inline validation errors
      return
    }

    button.classList.add("disabled")
    button.setAttribute("disabled", "disabled")
    button.innerText = "Submitting..."
  }
}
