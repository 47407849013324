import { Controller } from "@hotwired/stimulus"
import { isEmpty } from "lodash"

// Connects to data-controller="document-switcher"
export default class extends Controller {
  static targets = ["turboFrame"]

  turboFrameTarget: HTMLDivElement

  loadDocument(event) {
    const url = this.getDocumentUrl(event.currentTarget)

    if (url) {
      Turbo.visit(url, { frame: this.turboFrameTarget.id })
    }
  }

  private getDocumentUrl(target: HTMLDivElement) {
    if (target.dataset.documentUrl) {
      return target.dataset.documentUrl
    } else if (
      target.dataset.documentBaseUrl &&
      target.dataset.documentIdKey &&
      this.getDocumentId(target.dataset, target.dataset.documentIdKey)
    ) {
      const baseUrl = target.dataset.documentBaseUrl
      const documentId = this.getDocumentId(target.dataset, target.dataset.documentIdKey)
      return baseUrl.replace("DOCUMENT_ID", documentId)
    }
  }

  private getDocumentId(dataset: DOMStringMap, key: string) {
    return dataset[key] || null
  }
}
