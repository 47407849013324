import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ai--global-assistant-context"
export default class extends Controller {
  static targets = ["contextText", "generalTooltip", "specificTooltip", "contextLocation"]
  static values = { contextMap: Object, initialVisibility: Boolean }

  contextTextTarget: HTMLElement
  generalTooltipTarget: HTMLElement
  specificTooltipTarget: HTMLElement
  contextLocationTarget: HTMLElement

  contextMapValue: { [key: string]: string }
  initialVisibilityValue: boolean

  connect() {
    this.updateContext()

    if (this.initialVisibilityValue) this.toggleContextNote("show")

    document.addEventListener("turbo:load", this.updateContext)
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.updateContext)
  }

  getContextFromPath(path: string): {
    contextText: string
    contextLocation: string
    contextType: "general" | "refined"
  } {
    const matchedPath = Object.entries(this.contextMapValue).find(([key]) => path.includes(key))

    return matchedPath
      ? { contextText: "Refined Context", contextLocation: matchedPath[1], contextType: "refined" }
      : { contextText: "General Context", contextLocation: "", contextType: "general" }
  }

  // triggered from ai--global-assistant-panels#togglePanel
  handleTogglePanel = (e) => {
    if (e?.detail?.panel === "greetings") {
      this.toggleContextNote("show")
    } else {
      this.toggleContextNote("hide")
    }
  }

  toggleContextNote = (action: "show" | "hide") => {
    if (action === "show") {
      this.element.classList.add("relative")
      this.element.classList.remove("opacity-0", "translate-y-4")
    } else if (action === "hide") {
      this.element.classList.remove("relative")
      this.element.classList.add("opacity-0", "translate-y-4")
    }
  }

  updateContext = () => {
    const path = window.location.pathname
    const { contextText, contextLocation, contextType } = this.getContextFromPath(path)
    const contextSpan = this.element.querySelector(".context-text")

    if (contextSpan) {
      contextSpan.textContent = contextText

      const isGeneral = contextType === "general"
      this.generalTooltipTarget.classList.toggle("hidden", !isGeneral)
      this.specificTooltipTarget.classList.toggle("hidden", isGeneral)

      if (!isGeneral) {
        this.contextLocationTarget.textContent = contextLocation
      }
    }
  }
}
