import { Controller } from "@hotwired/stimulus"
import { enable, disable, hide } from "../../../utils"

// Connects to data-controller="ai--policy-compliance-checks--references-badge-component"
export default class extends Controller {
  static targets = ["checkbox"]

  checkboxTarget: HTMLInputElement

  connect() {
    this.deselectkOtherRadios = this.deselectOtherRadios.bind(this)
  }

  toggle(_event) {
    this.deselectOtherRadios()

    if (this.checkboxTarget.checked && this.checkboxTarget.dataset.toggled === "true") {
      this.checkboxTarget.checked = false
      this.checkboxTarget.dataset.toggled = "false"
    } else {
      this.checkboxTarget.dataset.toggled = "true"
    }
  }

  deselectOtherRadios() {
    const radios = document.querySelectorAll(`input[name="policy-check-references-toggle-group"]`)
    radios.forEach((radio) => {
      if (radio.id === this.checkboxTarget.id) return

      radio.checked = false
      radio.dataset.toggled = "false"
    })
  }
}
