/*
 ** This plugin allows you to customize the y-axis ticks of a chart.
 ** You can use it to display a prefix or suffix in the y-axis ticks.
 ** Note: Ensure each y-axis in your chart has a unique ID.
 **
 ** If you need to display currency formatting for a y-axis, you can pass the configuration
 ** directly into the chart options without using this plugin. This plugin is primarily useful
 ** when your chart has multiple y-axes, and only one of them requires custom formatting.
 **
 ** Usage:
 ** <%= render Charts::BarChartComponent.new(
 **   ...
 **   custom_y_axis_ticks: {
 **     enabled: true,
 **     yAxisTicks: [
 **       {
 **         id: "currencyYAxis",
 **         currency: "USD"
 **       },
 **       {
 **         id: "percentageYAxis",
 **         suffix: "%"
 **       }
 **     ]
 **   },
 **   options: {
 **     scales: {
 **       currencyYAxis: { position: "left" },
 **       percentageYAxis: { position: "right" }
 **     }
 **   }
 ** ) %>
 */

const customYAxisTicks = {
  id: "customyAxisTicks",
  beforeUpdate(chart) {
    const options = chart.options.plugins.customyAxisTicks
    const enabled = options?.enabled
    const yAxisTicks = options?.yAxisTicks

    if (!enabled || !Array.isArray(yAxisTicks)) return

    options.yAxisTicks.forEach(({ id, currency, prefix, suffix }) => {
      const yScale = chart?.scales[id]
      if (!yScale) return

      yScale.options.ticks.callback = (value) => {
        if (currency) {
          return new Intl.NumberFormat("en-US", { style: "currency", currency }).format(value)
        }

        if (prefix || suffix) {
          return `${prefix ?? ""}${value}${suffix ?? ""}`
        }

        return value
      }
    })
  },
}

export default customYAxisTicks
