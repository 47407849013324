import { Controller } from "@hotwired/stimulus"
import { PageAutoSubmitter } from "./page_auto_submit_controller"
import { IFormField } from "../../utils/constants"
import { find } from "lodash"

// Connects to data-controller="request-form--field-display"
export default class extends Controller {
  static targets = ["form", "nextButton", "previousButton", "loading", "currencyField"]
  formTarget: HTMLFormElement
  nextButtonTarget: HTMLInputElement
  previousButtonTarget: HTMLInputElement
  loadingTarget: HTMLInputElement
  currencyFieldTarget: HTMLInputElement

  static outlets = ["request-form--page-auto-submit"]
  requestFormPageAutoSubmitOutlet: PageAutoSubmitter

  static values = {
    fields: String,
    displayCriteria: String,
    displayOrder: Array,
    data: Object,
  }
  fieldsValue: string
  displayCriteriaValue: string
  displayOrderValue: string[]
  dataValue: object
  fieldsDisplayCriteria: object[]
  groupedFieldsDisplay: object

  connect(): void {
    const fields = this.getFields()
    const shouldRenderFields = !this.fieldsAreRendered(fields)

    if (shouldRenderFields) {
      this.buildFieldsRuleMap()
      const visibleFields = this.renderFields(fields)

      if (this.hasRequestFormPageAutoSubmitOutlet) {
        this.requestFormPageAutoSubmitOutlet.conditionallyAutoSubmitPage(visibleFields)
      }
    }
  }

  loadingTargetConnected(el) {
    el.classList.add("hidden")
  }

  previousButtonTargetConnected(el) {
    el.disabled = false
    el.classList.remove("disabled")
  }

  nextButtonTargetConnected(el) {
    el.disabled = false
    el.classList.remove("disabled")
  }

  disableValidation(event: Event) {
    const form = (event.target as any).form
    form.noValidate = true
  }

  fieldsAreRendered(fields: IFormField[]): boolean {
    const fieldRendered = document.getElementById(fields[0].key) || this.find(fields[0].key, "container")
    return fieldRendered ? true : false
  }

  buildFieldsRuleMap(): void {
    this.fieldsDisplayCriteria = JSON.parse(this.displayCriteriaValue)
    this.groupedFieldsDisplay = this.fieldsDisplayCriteria.reduce((group, displayRule) => {
      const { display, field } = displayRule
      group[display] = group[display] ?? []
      group[display].push({
        ...displayRule,
        display: this.isRuleApplied(displayRule, this.dataValue[field]),
      })
      return group
    }, {})
  }

  renderFields(fields: IFormField[]): IFormField[] {
    const fieldsToHide = []
    const visibleFields = []

    Object.entries(this.groupedFieldsDisplay).forEach(([fieldKey, rules]) => {
      const allRulesApplied = rules.every((displayRule) => displayRule.display)
      if (!allRulesApplied) {
        fieldsToHide.push(fieldKey)
      }
    })

    fields.forEach((field) => {
      if (!fieldsToHide.includes(field.key)) {
        let fieldTemplate = this.find(field.key, "template")
        if (fieldTemplate) {
          let newFormField = fieldTemplate.content.cloneNode(true)
          this.formTarget.appendChild(newFormField)
          visibleFields.push(field)
        }
      }
    })

    return visibleFields
  }

  getFields(): IFormField[] {
    return JSON.parse(this.fieldsValue).sort((a, b) => {
      let aOrderValue = this.displayOrderValue.indexOf(a.key)
      let bOrderValue = this.displayOrderValue.indexOf(b.key)

      // If order of the element is unknown then move to the end
      if (aOrderValue === -1) {
        aOrderValue = 1000
      }
      if (bOrderValue === -1) {
        bOrderValue = 1000
      }

      return aOrderValue - bOrderValue
    })
  }

  getFieldByKey(fieldKey): IFormField {
    return this.getFields().find(({ key }) => key == fieldKey)
  }

  getDisplayCriteria(field): any[] {
    return this.fieldsDisplayCriteria.filter((displayRule) => displayRule.field === field)
  }

  find(key, type: "container" | "template"): HTMLElement | null {
    return document.getElementById(`${key}_${type}`)
  }

  run(event): void {
    let fieldKey = this.getFieldKey(event)
    let fieldDisplayCriteria = this.getDisplayCriteria(fieldKey)
    if (fieldDisplayCriteria) {
      let field = this.getFieldByKey(fieldKey)
      const currentFieldValue = this.getFieldValue(field, event)

      this.sortDisplayCriteria(fieldDisplayCriteria)
        .reverse()
        .forEach((rule) => this.processRule(rule, currentFieldValue))
    }
  }

  sortDisplayCriteria(fieldDisplayCriteria: object[]): object[] {
    // sort the conditional form fields display criteria based on the form page's display order
    let sortedCriteria = this.displayOrderValue
      .map((value) => fieldDisplayCriteria.find((fieldDisplayCriterion) => fieldDisplayCriterion.display === value))
      .filter((o) => o)

    // it is possible that a form page's display order may not contain all of the conditional form fields
    // in this case they still need to be displayed so add then to the end of the array
    let itemsToAddBack = fieldDisplayCriteria.filter(
      (item) => !sortedCriteria.find((sortedItem) => JSON.stringify(sortedItem) === JSON.stringify(item)),
    )
    sortedCriteria.push(...itemsToAddBack)

    return sortedCriteria
  }

  getFieldValue(field, event): string | string[] {
    if (field && field.kind === "Checkbox") {
      return this.getCheckboxValue(field)
    }

    return event.target.value
  }

  getCheckboxValue(field): string[] {
    const checkedCheckboxes = document.querySelectorAll(`input[name='form[${field.key}][]']:checked`)
    return Array.from(checkedCheckboxes).map((checkbox) => checkbox.value)
  }

  getFieldKey(event): string {
    let parsedFieldName = event.target.name.match(/\w+\[(\w+)\]/)
    return parsedFieldName ? parsedFieldName[1] : event.target.name
  }

  processRule(rule, targetValue): void {
    this.updateGroupedFieldsDisplay(rule, targetValue)
    let fieldContainer = this.find(rule.display, "container")
    const allRulesApplied = this.groupedFieldsDisplay[rule.display].every((displayRule) => displayRule.display)

    if (!fieldContainer && allRulesApplied) {
      let triggerFieldContainer = this.find(rule.field, "container")
      let fieldTemplate = this.find(rule.display, "template")
      if (!fieldTemplate) {
        // If the field is not on the page, do nothing.
        // This will happen when display_criteria contains entries for fields that have been archived.
        return
      }
      let fieldContainer = fieldTemplate.content.cloneNode(true)
      this.formTarget.insertBefore(fieldContainer, triggerFieldContainer.nextSibling)
    } else if (fieldContainer && !allRulesApplied) {
      this.formTarget.removeChild(fieldContainer)
      this.hideChildren(rule.display)
    }
  }

  updateGroupedFieldsDisplay(rule, targetValue): void {
    this.groupedFieldsDisplay[rule.display] = this.groupedFieldsDisplay[rule.display].map((r) => {
      return r.field === rule.field ? { ...r, display: this.isRuleApplied(rule, targetValue) } : r
    }, [])
  }

  hideChildren(fieldKey): void {
    let fieldDisplayCriteria = this.getDisplayCriteria(fieldKey)
    if (fieldDisplayCriteria)
      fieldDisplayCriteria.forEach((rule) => {
        let fieldContainer = this.find(rule.display, "container")
        if (fieldContainer) {
          this.formTarget.removeChild(fieldContainer)
          this.hideChildren(rule.display)
        }
      })
  }

  isRuleApplied(displayRule, targetValue): Boolean | undefined {
    if (targetValue === undefined) return false
    switch (displayRule.operator) {
      case "eql":
        return displayRule.value == targetValue
      case "not_eql":
        return displayRule.value != targetValue
      case "incl":
        if (Array.isArray(targetValue)) {
          // When the `incl` operator is used in conjunction with a Checkbox, we try to find an
          // intersection between selected options and specified values.
          return targetValue.find((value) => displayRule.value.includes(value))
        } else {
          return displayRule.value.includes(targetValue)
        }
      case "excl":
        return !displayRule.value.includes(targetValue)
      case "lt_date":
        return new Date(targetValue) < this.parseDate(displayRule.value)
      case "lte_date":
        return new Date(targetValue) <= this.parseDate(displayRule.value)
      case "eql_date":
        return new Date(targetValue) == this.parseDate(displayRule.value)
      case "gte_date":
        return new Date(targetValue) >= this.parseDate(displayRule.value)
      case "gt_date":
        return new Date(targetValue) > this.parseDate(displayRule.value)
    }
  }

  parseDate(dateParams): Date {
    const { date, offset } = dateParams
    let parsedDate = date === "today" ? new Date() : new Date(date)
    if (offset != 0) {
      parsedDate.setDate(parsedDate.getDate() + offset)
    }
    return parsedDate
  }
}
