import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ai--global-assistant-tips"
export default class extends Controller {
  static targets = ["header", "tipList", "content", "backToTips"]

  backToTipsTarget: HTMLLinkElement
  headerTargets: HTMLElement[]
  tipListTargets: HTMLElement[]
  contentTargets: HTMLElement[]

  showContent(event) {
    event.preventDefault()

    // Show back to tips
    this.backToTipsTarget.classList.remove("hidden")

    // Hide all headers
    this.headerTargets.forEach((header) => header.classList.add("hidden"))

    // Get the clicked item's li and its content
    const listItem = event.currentTarget.closest("li")
    if (!listItem) return

    // Hide all list items in all tipLists except the clicked one
    this.tipListTargets.forEach((list) => {
      list.querySelectorAll(":scope > li").forEach((item) => {
        if (item !== listItem) {
          item.classList.add("!hidden")
          // Hide content for non-selected items
          const content = item.querySelector("[data-ai--global-assistant-tips-target='content']")
          if (content) content.classList.add("hidden")
        }
      })
    })

    // Add expand class to clicked item and ensure its content is visible
    listItem.classList.add("expand")
    const content = listItem.querySelector("[data-ai--global-assistant-tips-target='content']")
    if (content) content.classList.remove("hidden")
  }

  reset(event) {
    event.preventDefault()

    // Hide back to tips, show back to chat
    this.backToTipsTarget.classList.add("hidden")
    // Show all headers
    this.headerTargets.forEach((header) => header.classList.remove("hidden"))

    // Show all list items and remove expand class
    this.tipListTargets.forEach((list) => {
      list.querySelectorAll("li").forEach((item) => {
        item.classList.remove("!hidden", "expand")
      })
    })

    // Hide all content
    this.contentTargets.forEach((content) => {
      content.classList.add("hidden")
    })
  }
}
