import { Controller } from "@hotwired/stimulus"
import { isTomSelectInput, show } from "../utils"

// Connects to data-controller="suggested-ai-values"
export default class extends Controller {
  static targets = ["suggestedField", "finalField", "finalCurrencyField", "dropdown", "afterSelection"]

  suggestedFieldTargets: HTMLCollectionOf<Element>
  finalFieldTarget: HTMLInputElement
  dropdownTarget: HTMLButtonElement
  finalCurrencyFieldTarget: HTMLButtonElement
  afterSelectionTargets: HTMLElement[]
  hasFinalCurrencyFieldTarget: boolean
  hasAfterSelectionTarget: boolean

  selectSuggestedValue(e) {
    const idOfTarget = e.currentTarget.id
    const fieldForThisSuggestion = this.suggestedFieldTargets.find((element) => element.id == idOfTarget)
    if (!fieldForThisSuggestion) return

    if (isTomSelectInput(this.finalFieldTarget)) {
      this.finalFieldTarget.tomselect.setValue(fieldForThisSuggestion.dataset.suggestedValue)
    } else {
      this.finalFieldTarget.value = fieldForThisSuggestion.dataset.suggestedValue
      this.finalFieldTarget.classList.remove("is-empty")

      if (this.hasFinalCurrencyFieldTarget) {
        let currency = fieldForThisSuggestion.dataset.suggestedCurrency
        this.finalCurrencyFieldTarget.tomselect.setValue(currency)
      }
    }
    this.dropdownTarget.click()
    this.showAfterSelectionElements()
  }

  private showAfterSelectionElements() {
    if (!this.hasAfterSelectionTarget) {
      return
    }

    this.afterSelectionTargets.forEach((element) => {
      show(element)
    })
  }
}
